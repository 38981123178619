import { createSlice } from '@reduxjs/toolkit'


export const deviceFreeContainersStatusControllerSlice = createSlice({
    name: 'deviceFreeContainersStatusController',
    initialState: {
        deviceFreeContainersList: null,
        collectedContainers: null,
        nonCollectedContainers: null,
        todayShouldCollectContainers: null,
    },

    reducers: {
        setDeviceFreeContainersList: (state, action) => {
            state.deviceFreeContainersList = action.payload
        },
        setCollectedContainers: (state, action) => {
            state.collectedContainers = action.payload
        },
        setNonCollectedContainers: (state, action) => {
            state.nonCollectedContainers = action.payload
        },
        setTodayShouldCollectContainers: (state, action) => {
            state.todayShouldCollectContainers = action.payload
        },

    }
})


export const { setDeviceFreeContainersList, setCollectedContainers, setNonCollectedContainers, setTodayShouldCollectContainers } = deviceFreeContainersStatusControllerSlice.actions

export default deviceFreeContainersStatusControllerSlice.reducer


