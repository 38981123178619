import { createSlice } from '@reduxjs/toolkit'


export const chauffeurWatchControllerSlice = createSlice({
    name: 'chauffeurWatchControllerSlice',
    initialState: {

        currentChauffeurData: null,
        allChauffeurData: [],
        selectedChauffeurContainersCalculatedRouteList: [],
        selectedChauffeurContainers: [],
        selectedChauffeurCollectedContainers: [],
        selectedChauffeurNonCollectedContainers: [],
        selectedChauffeurDirections: [],
        selectedChauffeurRouteLatLng: null,
        totalChauffeurDuty: 0,
        totalContainers: 0,
        selectedChauffeurRoute: null,
        selectedChauffeurAllContainersRouteInfoList: [],
        //* Bu değişkenler sadece pasif şoförün rotasını bitirmek için gizli tıklama sayısını tutar
        secretClickCountForDisabledChauffeur: { id: 0, count: 0, isSecretKey: false },

        // Panel Controller
        suggestRoute: {
            show: false,
            value: null,
        },

        //* Şoförü sürücü modunda izlemek için TRUE yapılır
        isChauffeurMode: false,

        //* Şoförü sürücü modunda izlerken harita üzerinde popup açılması için TRUE yapılır
        isPopupOpenForSelectedUser: false,

    },

    reducers: {
        setCurrentChauffeurData: (state, action) => {
            state.currentChauffeurData = action.payload
        },
        setAllChauffeurData: (state, action) => {
            state.allChauffeurData = action.payload
        },
        setSelectedChauffeurContainers: (state, action) => {
            state.selectedChauffeurContainers = action.payload
        },
        setSelectedChauffeurCollectedContainers: (state, action) => {
            state.selectedChauffeurCollectedContainers = action.payload
        },
        setSelectedChauffeurNonCollectedContainers: (state, action) => {
            state.selectedChauffeurNonCollectedContainers = action.payload
        },
        setSelectedChauffeurDirections: (state, action) => {
            state.selectedChauffeurDirections = action.payload
        },
        setSelectedChauffeurRouteLatLng: (state, action) => {
            state.selectedChauffeurRouteLatLng = action.payload
        },
        setSuggestRoute: (state, action) => {
            state.suggestRoute = action.payload
        },
        setSelectedChauffeurContainersCalculatedRouteList: (state, action) => {
            state.selectedChauffeurContainersCalculatedRouteList = action.payload
        },
        setTotalChauffeurDuty: (state, action) => {
            state.totalChauffeurDuty = action.payload
        },
        setTotalContainers: (state, action) => {
            state.totalContainers = action.payload
        },
        setSelectedChauffeurRoute: (state, action) => {
            state.selectedChauffeurRoute = action.payload
        },
        setSelectedChauffeurAllContainersRouteInfoList: (state, action) => {
            state.selectedChauffeurAllContainersRouteInfoList = action.payload
        },
        setIsChauffeurMode: (state, action) => {
            state.isChauffeurMode = action.payload
        },
        setIsPopupOpenForSelectedUser: (state, action) => {
            state.isPopupOpenForSelectedUser = action.payload
        },
        setSecretClickCountForDisabledChauffeur: (state, action) => {
            state.secretClickCountForDisabledChauffeur = action.payload
        }
    }
})


export const {
    setCurrentChauffeurData,
    setAllChauffeurData,
    setSelectedChauffeurContainers,
    setSelectedChauffeurCollectedContainers,
    setSelectedChauffeurNonCollectedContainers,
    setSelectedChauffeurDirections,
    setSelectedChauffeurRouteLatLng,
    setSuggestRoute,
    setSelectedChauffeurContainersCalculatedRouteList,
    setTotalChauffeurDuty,
    setTotalContainers,
    setSelectedChauffeurRoute,
    setSelectedChauffeurAllContainersRouteInfoList,
    setIsChauffeurMode,
    setIsPopupOpenForSelectedUser,
    setSecretClickCountForDisabledChauffeur
} = chauffeurWatchControllerSlice.actions

export default chauffeurWatchControllerSlice.reducer


