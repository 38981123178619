import { createSlice } from '@reduxjs/toolkit'


export const googleMapsControllerSlice = createSlice({
    name: 'googleMapsControllerSlice',
    initialState: {
        map: null,
        isApiLoaded: false,
        centerMap: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).map_center_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).map_center_long],
        companyLocation: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).center_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).center_long],
        garbageLocation: [localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).garbage_dump_site_lat, localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).garbage_dump_site_long],
        companyName: localStorage.getItem('company') && JSON.parse(localStorage.getItem('company')).company_name,
        currentLocation: null,
        isClickedFindMeButton: false,
        allContainersData: null,
        allInfoForMapPage: null,
        infoWindowIndex: null,
        selectNewLocationButtonIsClicked: false,
        selectNewRouteLocationButtonIsClicked: false,
        newSelectedContainerLocation: null,
        newSelectedRouteContainerLocation: null,
        sortContainerButtonIsClicked: false,
        sortedContainerList: [],
        notSortedContainerList: [],
        hideSortedContainersButtonClicked: false,
        hideSortedContainersList: [],
        markerList: {},
        //* - Harita ekrannıdaki filtreleme işlemleri için kullanılan prop. Filtreler mahalle sokak bölge ve konteyner tipi
        withFilterDataProps: null,
        withFilterDataPropsValue: null,
        withFilterDataPropsValueLoading: false,
    },

    reducers: {
        setMap: (state, action) => {
            state.map = action.payload
        },
        setApiLoaded: (state, action) => {
            state.isApiLoaded = action.payload
        },
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload
        },
        setIsClickedFindMeButton: (state, action) => {
            state.isClickedFindMeButton = action.payload
        },
        setAllContainersData: (state, action) => {
            state.allContainersData = action.payload
        },
        setAllInfoForMapPage: (state, action) => {
            state.allInfoForMapPage = action.payload
        },
        setCenterMap: (state, action) => {
            state.centerMap = action.payload
        },
        setCompanyLocation: (state, action) => {
            state.companyLocation = action.payload
        },
        setGarbageLocation: (state, action) => {
            state.garbageLocation = action.payload
        },
        setInfoWindowIndex: (state, action) => {
            state.infoWindowIndex = action.payload
        },
        setSelectNewLocationButtonIsClicked: (state, action) => {
            state.selectNewLocationButtonIsClicked = action.payload
        },
        setNewSelectedContainerLocation: (state, action) => {
            state.newSelectedContainerLocation = action.payload
        },
        setNewSelectedRouteContainerLocation: (state, action) => {
            state.newSelectedRouteContainerLocation = action.payload
        },
        setSelectNewRouteLocationButtonIsClicked: (state, action) => {
            state.selectNewRouteLocationButtonIsClicked = action.payload
        },
        setSortContainerButtonIsClicked: (state, action) => {
            state.sortContainerButtonIsClicked = action.payload
        },
        setSortedContainerList: (state, action) => {
            state.sortedContainerList = action.payload
        },
        setNotSortedContainerList: (state, action) => {
            state.notSortedContainerList = action.payload
        },
        setMarkerList: (state, action) => {
            state.markerList = action.payload
        },
        setHideSortedContainersButtonClicked: (state, action) => {
            state.hideSortedContainersButtonClicked = action.payload
        },
        setHideSortedContainersList: (state, action) => {
            state.hideSortedContainersList = action.payload
        },
        setWithFilterDataProps: (state, action) => {
            state.withFilterDataProps = action.payload
        },
        setWithFilterDataPropsValue: (state, action) => {
            state.withFilterDataPropsValue = action.payload
        },
        setWithFilterDataPropsValueLoading: (state, action) => {
            state.withFilterDataPropsValueLoading = action.payload
        }
    }
})


export const { setWithFilterDataPropsValueLoading, setWithFilterDataPropsValue, setWithFilterDataProps, setNewSelectedRouteContainerLocation, setSelectNewRouteLocationButtonIsClicked, setAllInfoForMapPage, setApiLoaded, setMarkerList, setHideSortedContainersList, setHideSortedContainersButtonClicked, setSortedContainerList, setSortContainerButtonIsClicked, setSelectNewLocationButtonIsClicked, setNewSelectedContainerLocation, setCurrentLocation, setIsClickedFindMeButton, setAllContainersData, setCenterMap, setInfoWindowIndex, setMap, setCompanyLocation, setGarbageLocation, setNotSortedContainerList } = googleMapsControllerSlice.actions

export default googleMapsControllerSlice.reducer