import { Modal } from 'antd';
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setIsMedicalWasteUser, setIsModalOpenAntd, setModalDataAntd } from '../../core/stores/antdModalControllerSlice';
import './AntdModal.scss'

function AntdModal() {
    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const antdModalController = useSelector(state => state.antdModalController)
    const dispatch = useDispatch();

    

    return (
        // style={{ padding: '16px' }}
        <div id='AntdModal'>
            {antdModalController.isMedicalWasteUser == true ?
                <Modal
                    
                    afterClose={() => {
                        dispatch(setModalDataAntd(<></>))
                        dispatch(setIsMedicalWasteUser(false));
                        console.log("Modal Closed")
                        return antdModalController.runFuncHandle()
                    }}
                    centered
                    maskClosable={false}
                    open={antdModalController.isModalOpen}
                    closeIcon={<></>}
                    closable={false}
                    onOk={() => dispatch(setIsModalOpenAntd(false))}
                    onCancel={() => dispatch(setIsModalOpenAntd(false))}
                    destroyOnClose={antdModalController.destroyOnClose}
                    footer={antdModalController.footer}
                    width={antdModalController.width}
                    style={antdModalController.style}
                >
                    {antdModalController.modalData}
                </Modal>
                :
                <Modal
                    title={antdModalController.modalCaption}
                    afterClose={() => {
                        dispatch(setModalDataAntd(<></>))
                        console.log("Modal Closed")
                        return antdModalController.runFuncHandle()
                    }}
                    centered
                    // confirmLoading={true}
                    maskClosable={false}
                    // okButtonProps={{ disabled:  true,  }}
                    footer={antdModalController.footer}
                    okText={antdModalController.okText}
                    open={antdModalController.isModalOpen}
                    onOk={() => dispatch(setIsModalOpenAntd(false))}
                    onCancel={() => dispatch(setIsModalOpenAntd(false))}
                    destroyOnClose={antdModalController.destroyOnClose}
                    width={antdModalController.width}
                    style={antdModalController.style}
                >
                    {antdModalController.modalData}
                </Modal>

            }
        </div>
    )
}

export default AntdModal